import { useState } from "react";
import { Box, CircularProgress, Link, Typography } from "@mui/material";
import { DatePicker } from "antd";
import { useEffect } from "react";
import { useLineContext } from "../../../hooks/useLineContext";
import { baseURL } from "../../../api/url";
import DataTable from "react-data-table-component";
import RedButton from "../../../components/ui/RedButton";
import locale from "antd/es/date-picker/locale/th_TH";
import "dayjs/locale/th";
import dayjs from "dayjs";
import axios from "axios";
const { RangePicker } = DatePicker;

const columns = [
    {
        name: "Point",
        width: "100px",
        sortable: true,
        selector: (row) => row.point,
        cell: (row) => (row.type === "Increase" ? `+${row.point}` : `-${row.point}`),
        conditionalCellStyles: [
            {
                when: (row) => row.type === "Increase",
                style: {
                    color: "#5cb85c",
                },
            },
            {
                when: (row) => row.type === "Decrease",
                style: {
                    color: "#FD0004",
                },
            },
        ],
    },
    {
        name: "Usage History",
        minWidth: "150px",
        sortable: true,
        selector: (row) => row.detail,
        style: {
            borderLeft: "1px solid white",
            borderRight: "1px solid white",
        },
    },
    {
        name: "Quantity",
        minWidth: "80px",
        sortable: true,
        selector: (row) => (row.qty ? row.qty : "-"),
        style: {
            borderLeft: "1px solid white",
            borderRight: "1px solid white",
            justifyContent: "center",
        },
    },
    {
        name: "Tracking",
        minWidth: "80px",
        sortable: false,
        cell: (row) =>
            row.delivery && row.tracking_url ? (
                <Link href={row.tracking_url} sx={{ textDecoration: "underline" }} target="_blank">
                    {row.delivery}
                </Link>
            ) : row.delivery && !row.tracking_url ? (
                row.delivery
            ) : (
                "-"
            ),
        style: {
            borderLeft: "1px solid white",
            borderRight: "1px solid white",
            justifyContent: "center",
        },
    },
    {
        name: "Date Time",
        minWidth: "100px",
        sortable: true,
        selector: (row) => (row.create_timestamp ? row.create_timestamp : "-"),
        format: (row) => (row.create_timestamp ? dayjs(row.create_timestamp).format("DD/MM/YYYY HH:mm:ss") : "-"),
    },
];

const customStyles = {
    headRow: {
        style: {
            fontSize: ".75rem",
            color: "white",
            background: "linear-gradient(0deg, #365CA0, #264373, #152741)",
        },
    },
    headCells: {
        style: {
            "&:nth-child(2)": {
                borderLeft: "1px solid white",
                borderRight: "1px solid white",
            },
            "&:nth-child(3)": {
                justifyContent: "center",
            },
            "&:nth-child(4)": {
                justifyContent: "center",
            },
        },
    },
    rows: {
        style: {
            background: "linear-gradient(90deg, #C2DCFF, #FFFFFF)",
            "&:not(:last-of-type)": {
                borderBottomColor: "white",
            },
        },
    },
    cells: {
        style: {
            padding: ".5rem",
            "& div:first-child": {
                whiteSpace: "pre-wrap !important",
                overflow: "auto !important",
                textOverflow: "unset !important",
            },
        },
    },
};

const HistoryTable = (props) => {
    const { liffToken } = useLineContext();
    const [rangeDate, setRangeDate] = useState([dayjs().subtract(1, "year"), dayjs()]);
    const [history, setHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const onCalendarChange = (values) => {
        setRangeDate(values);
    };

    const onSearchHistory = () => {
        const startDate = rangeDate ? dayjs(rangeDate[0]).format("YYYY-MM-DD") : "";
        const endDate = rangeDate ? dayjs(rangeDate[1]).format("YYYY-MM-DD") : "";

        axios
            .get(`${baseURL}/company_history`, {
                headers: { Authorization: `Bearer ${liffToken}` },
                params: { code_company: props.userInfo.code, startDate, endDate },
            })
            .then((res) => {
                if (res.data.Code === "20000") {
                    const newFormat = res.data.Data.map((item) => {
                        return {
                            ...item,
                            point: item.point.toFixed(2),
                            current_point: item.current_point.toFixed(2),
                            previous_point: item.previous_point.toFixed(2),
                        };
                    });

                    setHistory(newFormat);
                }

                setIsLoading(false);
            })
            .catch((e) => {
                throw new Error("Can't get company history", e);
            });
    };

    useEffect(() => {
        if (liffToken) {
            if (props.userInfo.code) {
                axios
                    .get(`${baseURL}/company_history`, {
                        headers: { Authorization: `Bearer ${liffToken}` },
                        params: {
                            code_company: props.userInfo.code,
                            startDate: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
                            endDate: dayjs().format("YYYY-MM-DD"),
                        },
                    })
                    .then((res) => {
                        if (res.data.Code === "20000") {
                            const newFormat = res.data.Data.map((item) => {
                                return {
                                    ...item,
                                    point: item.point.toFixed(2),
                                    current_point: item.current_point.toFixed(2),
                                    previous_point: item.previous_point.toFixed(2),
                                };
                            });

                            setHistory(newFormat);
                        }

                        setIsLoading(false);
                    })
                    .catch((e) => {
                        throw new Error("Can't get company history", e);
                    });
            }
        }
    }, [props.userInfo.code, liffToken]);

    return (
        <>
            <Box py={3} display="flex" justifyContent="space-between" gap={2} sx={{ position: "relative" }}>
                <RangePicker
                    locale={locale}
                    format="DD-MM-YYYY"
                    className="myDateRange"
                    value={rangeDate}
                    onCalendarChange={onCalendarChange}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    style={{ width: "100%", maxWidth: "350px", height: "50px" }}
                />
                <RedButton sx={{ width: "40%", maxWidth: "150px", borderRadius: "15px" }} onClick={onSearchHistory}>
                    ค้นหา
                </RedButton>
            </Box>
            <Box p={1} sx={{ backgroundColor: "white", borderRadius: "15px", border: "2px solid white" }}>
                <DataTable
                    columns={columns}
                    data={history}
                    customStyles={customStyles}
                    defaultSortFieldId={5}
                    defaultSortAsc={false}
                    progressPending={isLoading}
                    progressComponent={
                        <Box
                            py={5}
                            sx={{
                                width: "100%",
                                height: "100%",
                                background: "linear-gradient(90deg, #E3EFFF, #FFFFFF)",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    }
                    noDataComponent={
                        <Box
                            py={5}
                            sx={{
                                width: "100%",
                                height: "100%",
                                background: "linear-gradient(90deg, #E3EFFF, #FFFFFF)",
                            }}
                        >
                            <Typography align="center" sx={{ fontSize: "1.25rem", color: "#004B61" }}>
                                ไม่พบประวัติการใช้งาน
                            </Typography>
                        </Box>
                    }
                />
            </Box>
        </>
    );
};

export default HistoryTable;
